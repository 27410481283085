/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-09-09",
    versionChannel: "nightly",
    buildDate: "2023-09-09T00:20:26.280Z",
    commitHash: "f092fa5809b76c6f6ba5f6dc2f07ce0b73b3427b",
};
